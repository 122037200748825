import store from '@/store';

/**
 * 字符权限校验
 * @param {Array} value 校验值
 * @returns {Boolean}
 */
export function checkPermi(value) {
  if (value && value instanceof Array && value.length > 0) {
    var permissions = store.getters && store.getters.permissions;
    var permissionDatas = value;
    var all_permission = "*:*:*";
    var hasPermission = permissions.some(function (permission) {
      return all_permission === permission || permissionDatas.includes(permission);
    });
    if (!hasPermission) {
      return false;
    }
    return true;
  } else {
    // console.error(`need roles! Like checkPermi="['system:user:add','system:user:edit']"`)
    return false;
  }
}

/**
 * 角色权限校验
 * @param {Array} value 校验值
 * @returns {Boolean}
 */
export function checkRole(value) {
  if (value && value instanceof Array && value.length > 0) {
    var roles = store.getters && store.getters.roles;
    var permissionRoles = value;
    var super_admin = "admin";
    var hasRole = roles.some(function (role) {
      return super_admin === role || permissionRoles.includes(role);
    });
    if (!hasRole) {
      return false;
    }
    return true;
  } else {
    console.error("need roles! Like checkRole=\"['admin','editor']\"");
    return false;
  }
}