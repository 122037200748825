var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    { staticClass: "box-card", attrs: { shadow: "never" } },
    [
      _c("div", { staticClass: "acea-row row-middle" }, [
        _c(
          "div",
          {
            directives: [{ name: "db-click", rawName: "v-db-click" }],
            staticClass: "font-sm after-line",
            on: { click: _vm.goBack },
          },
          [
            _c("span", { staticClass: "el-icon-arrow-left" }),
            _vm._v(" "),
            _c("span", { staticClass: "pl10" }, [_vm._v("返回")]),
          ]
        ),
        _vm._v(" "),
        _c("p", { staticClass: "ht_title ml10" }, [
          _vm._v("\n      " + _vm._s(_vm.title) + "\n      "),
          _vm.subTitle
            ? _c("span", { staticClass: "st_title" }, [
                _vm._v(_vm._s(_vm.subTitle)),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }