function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UploadIndex from '@/components/uploadPicture/index.vue';
export default {
  name: 'UploadFroms',
  components: {
    UploadIndex: UploadIndex
  },
  props: {
    value: {},
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      image: "",
      visible: false,
      callback: function callback() {},
      isMore: '',
      imageList: []
    };
  },
  beforeMount: function beforeMount() {
    if (this.multiple) {
      // 接收 v-model 数据
      if (this.value) {
        this.imageList = JSON.parse(this.value);
      }
    } else {
      // 接收 v-model 数据
      if (this.value) {
        this.image = this.value;
      }
    }
    // 处理多选
    this.isMore = this.multiple ? '2' : '1';
  },
  methods: {
    handleClose: function handleClose() {
      this.visible = false;
    },
    getImage: function getImage(img) {
      if (this.multiple) {
        var obj = {};
        this.imageList = img.reduce(function (cur, next) {
          obj[next.attId] ? "" : obj[next.attId] = true && cur.push(next);
          return cur;
        }, []);
        this.$emit('input', JSON.stringify(this.imageList));
      } else {
        this.image = img[0].sattDir;
        this.$emit('input', this.image);
      }
      this.visible = false;
    },
    // 点击商品图
    modalPicTap: function modalPicTap(tit, num, i) {
      this.visible = true;
    },
    handleRemove: function handleRemove(i) {
      this.imageList.splice(i, 1);
      this.$emit('input', JSON.stringify(this.imageList));
    },
    // 移动
    handleDragStart: function handleDragStart(e, item) {
      this.dragging = item;
    },
    handleDragEnd: function handleDragEnd(e, item) {
      this.dragging = null;
    },
    handleDragOver: function handleDragOver(e) {
      e.dataTransfer.dropEffect = 'move';
    },
    handleDragEnter: function handleDragEnter(e, item) {
      e.dataTransfer.effectAllowed = 'move';
      if (item === this.dragging) {
        return;
      }
      var newItems = _toConsumableArray(this.imageList);
      var src = newItems.indexOf(this.dragging);
      var dst = newItems.indexOf(item);
      newItems.splice.apply(newItems, [dst, 0].concat(_toConsumableArray(newItems.splice(src, 1))));
      this.imageList = newItems;
    }
  }
};