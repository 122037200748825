//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    backUrl: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  },
  methods: {
    goBack: function goBack() {
      if (this.backUrl) {
        this.$router.push({
          path: this.backUrl
        });
      } else {
        this.$router.back();
      }
    }
  }
};