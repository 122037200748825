import * as constants from "@/utils/constants.js";
import { formatDates } from "@/utils/index";

// 公共过滤器
export function filterEmpty(val) {
  var _result = "-";
  if (!val) {
    return _result;
  }
  _result = val;
  return _result;
}

/**
 * @description 金额过滤器
 */
export function moneyFilter(cost) {
  var lebal = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "";
  var money = Number(cost).toFixed(2).split(".");
  money[0] = money[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return lebal + money.join(".");
}

// 时间过滤器
export function formatDate(time) {
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "yyyy-MM-dd hh:mm:ss";
  time = Number(time);
  if (time !== 0) {
    var date = 0;
    if (String(time).length > 10) {
      date = new Date(time);
    } else {
      date = new Date(time * 1000);
    }
    return formatDates(date, format);
  }
}
export function filterYesOrNo(value) {
  return value ? "是" : "否";
}
export function filterShowOrHide(value) {
  return value ? "显示" : "不显示";
}
export function filterShowOrHideForFormConfig(value) {
  return value === "‘0’" ? "显示" : "不显示";
}
export function filterYesOrNoIs(value) {
  return value ? "否" : "是";
}
export function filterCategroyType(value) {
  return constants.categoryType.filter(function (item) {
    return value === item.value;
  })[0].name;
}
export function filterConfigCategory(value) {
  return constants.configCategory.filter(function (item) {
    return value === item.value;
  })[0].label;
}

/**
 * @description 公众号回复类型
 */
export function keywordStatusFilter(status) {
  var statusMap = {
    text: "文字消息",
    image: "图片消息",
    news: "图文消息",
    voice: "声音消息"
  };
  return statusMap[status];
}

/**
 * @description 优惠券类型
 */
export function couponUserTypeFilter(status) {
  var statusMap = {
    1: "通用券",
    2: "商品券",
    3: "品类券"
  };
  return statusMap[status];
}

/**
 * @description 优惠券领取方式
 */
export function couponTypeFilter(status) {
  var statusMap = {
    1: "手动领取",
    2: "新人券",
    3: "赠送券"
  };
  return statusMap[status];
}

/**
 * @description 文章分类
 */
export function articleTypeFilter(status) {
  if (!status) {
    return "";
  }
  var arrayList = JSON.parse(localStorage.getItem("articleClass"));
  if (arrayList.filter(function (item) {
    return Number(status) === Number(item.id);
  }).length < 1) {
    return "";
  }
  return arrayList.filter(function (item) {
    return Number(status) === Number(item.id);
  })[0].name;
}

/**
 * @description 支付方式
 */
export function payTypeFilter(status) {
  var payMap = {
    weixin: "微信支付",
    alipay: "支付宝支付",
    yue: "余额支付"
  };
  return payMap[status];
}

/**
 * @description 支付状态
 */
export function payStatusFilter(status) {
  var statusMap = {
    false: "未支付",
    true: "已支付"
  };
  return statusMap[status];
}

/**
 * @description 提现方式
 */
export function extractTypeFilter(status) {
  var statusMap = {
    bank: "银行卡",
    alipay: "支付宝",
    weixin: "微信"
  };
  return statusMap[status];
}

/**
 * @description 充值类型
 */
export function rechargeTypeFilter(status) {
  var statusMap = {
    public: "微信公众号",
    weixinh5: "微信H5支付",
    routine: "小程序"
  };
  return statusMap[status];
}

/**
 * @description 财务审核状态
 */
export function extractStatusFilter(status) {
  var statusMap = {
    "-1": "已拒绝",
    0: "审核中",
    1: "已提现"
  };
  return statusMap[status];
}

/**
 * @description 砍价状态
 */
export function bargainStatusFilter(status) {
  var statusMap = {
    1: "进行中",
    2: "未完成",
    3: "已成功"
  };
  return statusMap[status];
}

/**
 * @description 砍价状态
 */
export function bargainColorFilter(status) {
  var statusMap = {
    1: "",
    2: "danger",
    3: "success"
  };
  return statusMap[status];
}

/**
 * @description 拼团状态
 */
export function groupStatusFilter(status) {
  var statusMap = {
    1: "进行中",
    2: "已成功",
    3: "未完成"
  };
  return statusMap[status];
}

/**
 * @description 拼团状态
 */
export function groupColorFilter(status) {
  var statusMap = {
    1: "",
    2: "success",
    3: "danger"
  };
  return statusMap[status];
}

/**
 * @description 一号通tab值
 */
export function onePassTypeFilter(status) {
  var statusMap = {
    sms: "短信",
    copy: "商品采集",
    expr_query: "物流查询",
    expr_dump: "电子面单打印"
  };
  return statusMap[status];
}

/**
 * @description 视频号商品草稿状态
 */
export function editStatusFilter(status) {
  var statusMap = {
    1: "未审核",
    2: "审核中",
    3: "审核失败",
    4: "审核成功"
  };
  return statusMap[status];
}

/**
 * @description 视频号正式商品状态
 */
export function videoStatusFilter(status) {
  var statusMap = {
    0: "初始值",
    5: "上架",
    11: "自主下架",
    13: "违规下架/风控系统下架"
  };
  return statusMap[status];
}