import "core-js/modules/es6.array.copy-within";
import "core-js/modules/es6.array.fill";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.array.find-index";
import "core-js/modules/es6.array.from";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.array.of";
import "core-js/modules/es6.array.sort";
import "core-js/modules/es6.array.species";
import "core-js/modules/es6.date.to-primitive";
import "core-js/modules/es6.function.has-instance";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.map";
import "core-js/modules/es6.math.acosh";
import "core-js/modules/es6.math.asinh";
import "core-js/modules/es6.math.atanh";
import "core-js/modules/es6.math.cbrt";
import "core-js/modules/es6.math.clz32";
import "core-js/modules/es6.math.cosh";
import "core-js/modules/es6.math.expm1";
import "core-js/modules/es6.math.fround";
import "core-js/modules/es6.math.hypot";
import "core-js/modules/es6.math.imul";
import "core-js/modules/es6.math.log1p";
import "core-js/modules/es6.math.log10";
import "core-js/modules/es6.math.log2";
import "core-js/modules/es6.math.sign";
import "core-js/modules/es6.math.sinh";
import "core-js/modules/es6.math.tanh";
import "core-js/modules/es6.math.trunc";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.number.epsilon";
import "core-js/modules/es6.number.is-finite";
import "core-js/modules/es6.number.is-integer";
import "core-js/modules/es6.number.is-nan";
import "core-js/modules/es6.number.is-safe-integer";
import "core-js/modules/es6.number.max-safe-integer";
import "core-js/modules/es6.number.min-safe-integer";
import "core-js/modules/es6.number.parse-float";
import "core-js/modules/es6.number.parse-int";
import "core-js/modules/es6.object.assign";
import "core-js/modules/es7.object.define-getter";
import "core-js/modules/es7.object.define-setter";
import "core-js/modules/es7.object.entries";
import "core-js/modules/es6.object.freeze";
import "core-js/modules/es6.object.get-own-property-descriptor";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.get-own-property-names";
import "core-js/modules/es6.object.get-prototype-of";
import "core-js/modules/es7.object.lookup-getter";
import "core-js/modules/es7.object.lookup-setter";
import "core-js/modules/es6.object.prevent-extensions";
import "core-js/modules/es6.object.is";
import "core-js/modules/es6.object.is-frozen";
import "core-js/modules/es6.object.is-sealed";
import "core-js/modules/es6.object.is-extensible";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.object.seal";
import "core-js/modules/es6.object.set-prototype-of";
import "core-js/modules/es7.object.values";
import "core-js/modules/es6.promise";
import "core-js/modules/es7.promise.finally";
import "core-js/modules/es6.reflect.apply";
import "core-js/modules/es6.reflect.construct";
import "core-js/modules/es6.reflect.define-property";
import "core-js/modules/es6.reflect.delete-property";
import "core-js/modules/es6.reflect.get";
import "core-js/modules/es6.reflect.get-own-property-descriptor";
import "core-js/modules/es6.reflect.get-prototype-of";
import "core-js/modules/es6.reflect.has";
import "core-js/modules/es6.reflect.is-extensible";
import "core-js/modules/es6.reflect.own-keys";
import "core-js/modules/es6.reflect.prevent-extensions";
import "core-js/modules/es6.reflect.set";
import "core-js/modules/es6.reflect.set-prototype-of";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.flags";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.search";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.set";
import "core-js/modules/es6.symbol";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.string.anchor";
import "core-js/modules/es6.string.big";
import "core-js/modules/es6.string.blink";
import "core-js/modules/es6.string.bold";
import "core-js/modules/es6.string.code-point-at";
import "core-js/modules/es6.string.ends-with";
import "core-js/modules/es6.string.fixed";
import "core-js/modules/es6.string.fontcolor";
import "core-js/modules/es6.string.fontsize";
import "core-js/modules/es6.string.from-code-point";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.string.italics";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.string.link";
import "core-js/modules/es7.string.pad-start";
import "core-js/modules/es7.string.pad-end";
import "core-js/modules/es6.string.raw";
import "core-js/modules/es6.string.repeat";
import "core-js/modules/es6.string.small";
import "core-js/modules/es6.string.starts-with";
import "core-js/modules/es6.string.strike";
import "core-js/modules/es6.string.sub";
import "core-js/modules/es6.string.sup";
import "core-js/modules/es6.typed.array-buffer";
import "core-js/modules/es6.typed.int8-array";
import "core-js/modules/es6.typed.uint8-array";
import "core-js/modules/es6.typed.uint8-clamped-array";
import "core-js/modules/es6.typed.int16-array";
import "core-js/modules/es6.typed.uint16-array";
import "core-js/modules/es6.typed.int32-array";
import "core-js/modules/es6.typed.uint32-array";
import "core-js/modules/es6.typed.float32-array";
import "core-js/modules/es6.typed.float64-array";
import "core-js/modules/es6.weak-map";
import "core-js/modules/es6.weak-set";
import "core-js/modules/web.timers";
import "core-js/modules/web.immediate";
import "core-js/modules/web.dom.iterable";
import "regenerator-runtime/runtime";
import Vue from "vue";
// import 'babel-polyfill'
import Cookies from "js-cookie";
import "normalize.css/normalize.css"; // a modern alternative to CSS resets
import Element from "element-ui";
import "./styles/element-variables.scss";
import "@/styles/index.scss"; // global css
import "@/assets/iconfont/iconfont";
import "@/assets/iconfont/iconfont.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import "vue-ydui/dist/ydui.base.css";
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree } from "@/utils/parsing";
// 懒加载
import VueLazyload from "vue-lazyload";
Vue.config.devtools = true;
import App from "./App";
import store from "./store";
import router from "./router";
import attrFrom from "./components/attrFrom";
import uploadPicture from "./components/uploadPicture/uploadFrom";
import goodListFrom from "./components/goodList/goodListFrom";
import couponFrom from "./components/couponList/couponFrom";
import articleFrom from "./components/articleList/articleFrom";
import UploadIndex from "@/components/uploadPicture/index.vue";
import UploadFile from "@/components/Upload/uploadFile.vue";
import modalForm from "@/utils/modalForm";
// import VueUeditorWrap from 'vue-ueditor-wrap'
import iconFrom from "./components/iconFrom";
import TimeSelect from "@/components/TimeSelect";
import pagesHeader from '@/components/pagesHeader';
import dialog from "@/libs/dialog";
import scroll from "@/libs/loading";
import schema from "async-validator";
// 切勿更改 此组件为表单生成中使用的图片上传组件
import SelfUpload from "@/components/uploadPicture/forGenrator/index.vue";
import util from "@/utils/utils";
import modalAttr from "@/libs/modal-attr";
import modalIcon from "@/libs/modal-icon";
import { modalSure } from "@/libs/public";
import timeOptions from "@/libs/timeOptions";
import { loadScriptQueue } from "@/components/FormGenerator/utils/loadScript";
import "./icons"; // icon
import "./permission"; // permission control
import "./utils/error-log"; // error integralLog
import * as filters from "./filters"; // global filters
import { parseQuery } from "@/utils";
import * as Auth from "@/libs/wechat";
import * as constants from "@/utils/constants.js";
import * as selfUtil from "@/utils/ZBKJIutil.js";
import plugins from "./plugins";
import directive from "./directive"; //directive

import VueDirectiveImagePreviewer from "vue-directive-image-previewer";
import "vue-directive-image-previewer/dist/assets/style.css";
if (store.getters.token) {
  store.dispatch("user/getlevel");
}
Vue.component('pagesHeader', pagesHeader);
Vue.use(VueDirectiveImagePreviewer, {
  animate: {
    duration: 200,
    delay: 0
  },
  zIndex: 999999,
  //层级显示
  previewSize: 20 //倍图
});

// 时间选择控制
import pickerOptions from "@/utils/pickerOptions";
Vue.prototype.$pickerOptions = pickerOptions;

// 生成的唯一标识UUID
import { v4 as uuidv4 } from "uuid";
if (!localStorage.getItem("UUID")) {
  var uuid = uuidv4();
  localStorage.setItem("UUID", uuid);
}
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require("./assets/imgs/no.png"),
  loading: require("./assets/imgs/moren.jpg"),
  attempt: 1,
  listenEvents: ["scroll", "wheel", "mousewheel", "resize", "animationend", "transitionend", "touchmove"]
});
Vue.use(uploadPicture);
Vue.use(goodListFrom);
Vue.use(couponFrom);
Vue.use(articleFrom);
Vue.use(VueAwesomeSwiper);
Vue.use(plugins);
Vue.use(directive);
Vue.component("attrFrom", attrFrom);
Vue.component("UploadIndex", UploadIndex);
Vue.component("SelfUpload", SelfUpload);
Vue.component("iconFrom", iconFrom);
Vue.component("uploadFile", UploadFile);
Vue.component("timeSelect", TimeSelect);
Vue.prototype.$modalSure = modalSure;
Vue.prototype.$modalAttr = modalAttr;
Vue.prototype.$modalIcon = modalIcon;
Vue.prototype.$modalForm = modalForm;
Vue.prototype.$dialog = dialog;
Vue.prototype.$scroll = scroll;
Vue.prototype.$wechat = Auth;
Vue.prototype.$util = util;
Vue.prototype.$constants = constants;
Vue.prototype.$selfUtil = selfUtil;
Vue.prototype.$timeOptions = timeOptions;
Vue.prototype.$validator = function (rule) {
  return new schema(rule);
};
Vue.prototype.handleTree = handleTree;
Vue.prototype.parseTime = parseTime;
Vue.prototype.resetForm = resetForm;
var cookieName = "VCONSOLE";
var query = parseQuery();
var urlSpread = query["spread"];
var vconsole = query[cookieName.toLowerCase()];
var md5Crmeb = "b14d1e9baeced9bb7525ab19ee35f2d2"; //CRMEB MD5 加密开启vconsole模式
var md5UnCrmeb = "3dca2162c4e101b7656793a1af20295c"; //UN_CREMB MD5 加密关闭vconsole模式

if (vconsole !== undefined) {
  if (vconsole === md5UnCrmeb && Cookies.has(cookieName)) Cookies.remove(cookieName);
} else vconsole = Cookies.get(cookieName);
if (vconsole !== undefined && vconsole === md5Crmeb) {
  Cookies.set(cookieName, md5Crmeb, 3600);
  var module = function module() {
    return import("vconsole");
  };
  module().then(function (Module) {
    new Module.default();
  });
}
// 自定义实现String 类型的replaceAll方法
String.prototype.replaceAll = function (s1, s2) {
  return this.replace(new RegExp(s1, "gm"), s2);
};
Vue.use(Element, {
  size: Cookies.get("size") || "mini" // set element-ui default size
});

// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
var $previewApp = document.getElementById("previewApp");
var childAttrs = {
  file: "",
  dialog: ' width="600px" class="dialog-width" v-if="visible" :visible.sync="visible" :modal-append-to-body="false" '
};
window.addEventListener("message", init, false);
function buildLinks(links) {
  var strs = "";
  links.forEach(function (url) {
    strs += "<link href=\"".concat(url, "\" rel=\"stylesheet\">");
  });
  return strs;
}
function init(event) {
  if (event.data.type === "refreshFrame") {
    var code = event.data.data;
    var attrs = childAttrs[code.generateConf.type];
    var links = "";
    if (Array.isArray(code.links) && code.links.length > 0) {
      links = buildLinks(code.links);
    }
    $previewApp.innerHTML = "".concat(links, "<style>").concat(code.css, "</style><div id=\"app\"></div>");
    if (Array.isArray(code.scripts) && code.scripts.length > 0) {
      loadScriptQueue(code.scripts, function () {
        newVue(attrs, code.js, code.html);
      });
    } else {
      newVue(attrs, code.js, code.html);
    }
  }
}
function newVue(attrs, main, html) {
  // eslint-disable-next-line no-eval
  main = eval("(".concat(main, ")"));
  main.template = "<div>".concat(html, "</div>");
  new Vue({
    components: {
      child: main
    },
    data: function data() {
      return {
        visible: true
      };
    },
    template: "<div><child ".concat(attrs, "/></div>")
  }).$mount("#app");
}
String.prototype.replaceAll = function (s1, s2) {
  return this.replace(new RegExp(s1, "gm"), s2);
};

//  添加crmeb chat 统计
// var __s = document.createElement("script");
// __s.src = `${SettingMer.apiBaseURL}/public/jsconfig/getcrmebchatconfig`;
// document.head.appendChild(__s);

new Vue({
  el: "#app",
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});