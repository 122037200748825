import Layout from '@/layout';
var userRouter = {
  path: '/user',
  component: Layout,
  redirect: '/user/index',
  name: 'User',
  meta: {
    title: '会员',
    icon: 'clipboard'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/user/list/index');
    },
    name: 'UserIndex',
    meta: {
      title: '会员管理',
      icon: ''
    }
  }, {
    path: 'grade',
    component: function component() {
      return import('@/views/user/grade/index');
    },
    name: 'Grade',
    meta: {
      title: '会员等级',
      icon: ''
    }
  }, {
    path: 'label',
    component: function component() {
      return import('@/views/user/group/index');
    },
    name: 'Label',
    meta: {
      title: '会员标签',
      icon: ''
    }
  }, {
    path: 'group',
    component: function component() {
      return import('@/views/user/group/index');
    },
    name: 'Group',
    meta: {
      title: '会员分组',
      icon: ''
    }
  }, {
    path: 'statistics/info/:id?',
    component: function component() {
      return import('@/views/user/info/index');
    },
    name: 'Info',
    meta: {
      title: '账户总览',
      noCache: true,
      activeMenu: "/user/index"
    },
    hidden: true
  }]
};
export default userRouter;