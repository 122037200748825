import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken } from "@/utils/auth";
import SettingMer from "@/utils/settingMer";
import { isPhone } from "@/libs/wechat";
import router from "@/router";
var service = axios.create({
  baseURL: SettingMer.apiBaseURL,
  timeout: 60000 // 过期时间
});

// request interceptor
service.interceptors.request.use(function (config) {
  if (!config.data) {
    config.data = true; // 解决请求没有参数时添加不上Content-Type问题
  }
  // 发送请求之前做的
  var token = !store.getters.token ? sessionStorage.getItem("token") : store.getters.token;
  if (token) {
    config.headers["Authori-zation"] = token;
  }
  if (localStorage.getItem("UUID")) {
    config.headers["YM-UUID"] = localStorage.getItem("UUID");
  }
  if (/get/i.test(config.method)) {
    config.params = config.params || {};
    config.params.temp = Date.parse(new Date()) / 1000;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (response) {
  var res = response.data;
  // if the custom code is not 20000, it is judged as an error.
  if (res.code === 401) {
    // to re-login
    Message.error("无效的会话，或者登录已过期，请重新登录。");
    return Promise.reject();
    // location.href = "/login";
  } else if (res.code === 403) {
    Message.error("没有权限访问。");
  }
  // 文件流请求过滤放行
  if (response.config.headers && response.config.headers.Stream && res) {
    return Promise.resolve(res);
  }
  if (res.code !== 200 && res.code !== 401) {
    if (isPhone()) {
      //移动端
      return Promise.reject(res || "Error");
    }
    Message({
      message: res.message || "Error",
      type: "error",
      duration: 5 * 1000
    });
    return Promise.reject();
  } else {
    return Promise.resolve(res.data);
  }
}, function (error) {
  console.log("error=====>", error);
  // 对响应错误做点什么
  if (String(error).indexOf("502") != -1) {
    Message.error("系统正在维护中，请稍后再重试！");
    setTimeout(function () {
      location.href = "/502";
      return Promise.reject(error);
    }, 300);
  } else {
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000
    });
    return Promise.reject(error);
  }
});
export default service;