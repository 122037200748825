import Clipboard from 'clipboard';
export default {
  bind: function bind(el, binding) {
    var clipboard = new Clipboard(el, {
      text: function text() {
        return binding.value.value;
      }
    });
    el.__success_callback__ = binding.value.success;
    el.__error_callback__ = binding.value.error;
    clipboard.on('success', function (e) {
      var callback = el.__success_callback__;
      callback && callback(e);
    });
    clipboard.on('error', function (e) {
      var callback = el.__error_callback__;
      callback && callback(e);
    });
    el.__clipboard__ = clipboard;
  },
  update: function update(el, binding) {
    el.__clipboard__.text = function () {
      return binding.value.value;
    };
    el.__success_callback__ = binding.value.success;
    el.__error_callback__ = binding.value.error;
  },
  unbind: function unbind(el, binding) {
    delete el.__success_callback__;
    delete el.__error_callback__;
    el.__clipboard__.destroy();
    delete el.__clipboard__;
  }
};