import request from "@/utils/request";
export function login(data) {
  return request({
    url: "/admin/login",
    method: "post",
    data: data
  });
}
export function getInfo(token) {
  return request({
    url: "/admin/getAdminInfoByToken",
    method: "get",
    params: {
      token: token
    }
  });
}
export function logout() {
  return request({
    url: "/admin/logout",
    method: "get"
  });
}

/**
 * 会员管理 列表
 * @param pram
 */
export function userListApi(params) {
  return request({
    url: "/admin/user/list",
    method: "get",
    params: params
  });
}

/**
 * 会员管理 修改
 * @param pram
 */
export function userUpdateApi(params, data) {
  return request({
    url: "/admin/user/update",
    method: "post",
    params: params,
    data: data
  });
}

/**
 * 会员管理等级 修改
 * @param pram
 */
export function userLevelUpdateApi(data) {
  return request({
    url: "/admin/user/update/level",
    method: "post",
    data: data
  });
}

/**
 * 会员管理 详情
 * @param pram
 */
export function userInfoApi(params) {
  return request({
    url: "/admin/user/info",
    method: "get",
    params: params
  });
}

/**
 * 会员管理 账户详情
 * @param pram
 */
export function infobyconditionApi(params) {
  return request({
    url: "/admin/user/infobycondition",
    method: "get",
    params: params
  });
}

/**
 * 会员管理 账户详情top数据
 * @param pram
 */
export function topdetailApi(params) {
  return request({
    url: "/admin/user/topdetail",
    method: "get",
    params: params
  });
}

/**
 * 会员管理 批量设置分组
 * @param pram
 */
export function groupPiApi(params) {
  return request({
    url: "/admin/user/group",
    method: "post",
    params: params
  });
}

/**
 * 会员管理 批量设置标签
 * @param pram
 */
export function tagPiApi(params) {
  return request({
    url: "/admin/user/tag",
    method: "post",
    params: params
  });
}

/**
 * 会员管理 积分余额
 * @param pram
 */
export function foundsApi(params) {
  return request({
    url: "/admin/user/operate/founds",
    method: "get",
    params: params
  });
}

/**
 * 会员管理 删除
 * @param pram
 */
export function userDeleteApi(params) {
  return request({
    url: "/admin/user/delete",
    method: "get",
    params: params
  });
}

/**
 * 会员等级 列表
 * @param pram
 */
export function levelListApi() {
  return request({
    url: "/admin/system/user/level/list",
    method: "get"
  });
}

/**
 * 会员等级 新增
 * @param pram
 */
export function levelSaveApi(data) {
  return request({
    url: "/admin/system/user/level/save",
    method: "post",
    data: data
  });
}

/**
 * 会员等级 编辑
 *  @param pram
 */
export function levelUpdateApi(params, data) {
  return request({
    url: "/admin/system/user/level/update/".concat(params),
    method: "post",
    // params,
    data: data
  });
}

/**
 * 会员等级 详情
 * @param pram
 */
export function levelInfoApi(params) {
  return request({
    url: "/admin/system/user/level/info",
    method: "get",
    params: params
  });
}

/**
 * 会员等级 删除
 * @param pram
 */
export function levelDeleteApi(id) {
  return request({
    url: "/admin/system/user/level/delete/".concat(id),
    method: "post"
  });
}

/**
 * 会员等级 是否显示
 * @param pram
 */
export function levelUseApi(data) {
  return request({
    url: "/admin/system/user/level/use",
    method: "post",
    data: data
  });
}

/**
 * 会员标签 列表
 * @param pram
 */
export function tagListApi(params) {
  return request({
    url: "/admin/user/tag/list",
    method: "get",
    params: params
  });
}

/**
 * 会员标签 新增
 * @param pram
 */
export function tagSaveApi(data) {
  return request({
    url: "/admin/user/tag/save",
    method: "post",
    data: data
  });
}

/**
 * 会员标签 编辑
 * @param pram
 */
export function tagUpdateApi(params, data) {
  return request({
    url: "/admin/user/tag/update",
    method: "post",
    params: params,
    data: data
  });
}

/**
 * 会员标签 详情
 * @param pram
 */
export function tagInfoApi(params) {
  return request({
    url: "/admin/user/tag/info",
    method: "get",
    params: params
  });
}

/**
 * 会员标签 删除
 * @param pram
 */
export function tagDeleteApi(params) {
  return request({
    url: "/admin/user/tag/delete",
    method: "get",
    params: params
  });
}

/**
 * 会员分组 列表
 * @param pram
 */
export function groupListApi(params) {
  return request({
    url: "/admin/user/group/list",
    method: "get",
    params: params
  });
}

/**
 * 会员分组 新增
 * @param pram
 */
export function groupSaveApi(data) {
  return request({
    url: "/admin/user/group/save",
    method: "post",
    data: data
  });
}

/**
 * 会员分组 编辑
 * @param pram
 */
export function groupUpdateApi(params, data) {
  return request({
    url: "/admin/user/group/update",
    method: "post",
    params: params,
    data: data
  });
}

/**
 * 会员分组 详情
 * @param pram
 */
export function groupInfoApi(params) {
  return request({
    url: "/admin/user/group/info",
    method: "get",
    params: params
  });
}

/**
 * 会员分组 删除
 * @param pram
 */
export function groupDeleteApi(params) {
  return request({
    url: "/admin/user/group/delete",
    method: "get",
    params: params
  });
}

/**
 *获取登录页图片
 */
export function getLoginPicApi() {
  return request({
    url: "/admin/getLoginPic",
    method: "get"
  });
}

/**
 * @description 验证码
 */
export function captchaApi() {
  return request({
    url: "/admin/validate/code/get",
    method: "get"
  });
}

/**
 * @description 修改上级推广人
 */
export function updateSpreadApi(data) {
  return request({
    url: "/admin/user/update/spread",
    method: "post",
    data: data
  });
}

/**
 * @description 修改手机号
 */
export function updatePhoneApi(params) {
  return request({
    url: "/admin/user/update/phone",
    method: "get",
    params: params
  });
}

/**
 * @description 用户账户总览
 */
export function statisticsInfo(params) {
  return request({
    url: "/admin/user/team/gather",
    method: "get",
    params: params
  });
}