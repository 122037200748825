import { on } from '@/libs/tools';
export default {
  inserted: function inserted(el, binding, vnode) {
    var triggerDom = document.querySelector(binding.value.trigger);
    triggerDom.style.cursor = 'move';
    var bodyDom = document.querySelector(binding.value.body);
    var pageX = 0;
    var pageY = 0;
    var transformX = 0;
    var transformY = 0;
    var canMove = false;
    var handleMousedown = function handleMousedown(e) {
      var transform = /\(.*\)/.exec(bodyDom.style.transform);
      if (transform) {
        transform = transform[0].slice(1, transform[0].length - 1);
        var splitxy = transform.split('px, ');
        transformX = parseFloat(splitxy[0]);
        transformY = parseFloat(splitxy[1].split('px')[0]);
      }
      pageX = e.pageX;
      pageY = e.pageY;
      canMove = true;
    };
    var handleMousemove = function handleMousemove(e) {
      var xOffset = e.pageX - pageX + transformX;
      var yOffset = e.pageY - pageY + transformY;
      if (canMove) bodyDom.style.transform = "translate(".concat(xOffset, "px, ").concat(yOffset, "px)");
    };
    var handleMouseup = function handleMouseup(e) {
      canMove = false;
    };
    on(triggerDom, 'mousedown', handleMousedown);
    on(document, 'mousemove', handleMousemove);
    on(document, 'mouseup', handleMouseup);
  },
  update: function update(el, binding, vnode) {
    if (!binding.value.recover) return;
    var bodyDom = document.querySelector(binding.value.body);
    bodyDom.style.transform = '';
  }
};